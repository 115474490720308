/**
 * MinersFactory | index page
 */

import React from "react"

import Main from "./../templates/main"
import SEO from "./../components/seo"

import "./../components/index.css"

const IndexPage = () => (
    <Main>
        <SEO title="Willkommen" />
        <div className="welcome-header">
            <section>
                <h1>MinersFactory Minecraft: BE Server</h1>
                <h2>Willkommen auf unserer neuen Website!</h2>
                <a
                    href="minecraft://?addExternalServer=MinersFactory|play.minersfactory.de:19132"
                    className="btn btn-outline-light"
                >
                    Auf dem Server spielen!
                </a>
            </section>
        </div>
        <div className="container">
            <section>
                <h1>Willkommen bei MinersFactory!</h1>
                <p>Wir sind ein Minecraft: Bedrock Edition - Server und bieten euch Citybuild-Server in den Spielmodi Survival und Kreativ sowie einen FFA-Server. Auch neue Funktionen sind geplant und kommen bald.</p>
                <a href="https://minecraftpocket-servers.com/server/86095/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">
                    Jetzt für MinersFactory voten!
                </a>
            </section>
        </div>
    </Main>
)

export default IndexPage
